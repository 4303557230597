import React, { createContext, useContext, useEffect, useState } from "react";
import { quoteFetchController } from "../dataController/dataController";
import { useLocation } from "./locationContext";

const QuoteContext = createContext({
  data: null,
});
export function QuoteProvider({ children }) {
  const [data, setData] = useState(null);
  const { getParamFromUrl } = useLocation();

  const reserveId = getParamFromUrl("reserve_id");

  const { transactionId } = useLocation();

  // console.log("quoteContext ACTIVE");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await quoteFetchController.fetchData(transactionId);
        setData(result.data);
      } catch (error) {
        console.warn(error);
      } finally {
        // console.warn("done quoteContext");
      }
    };

    if (!reserveId) {
      fetchData();
    }
  }, [transactionId, reserveId]);

  const isLink = data?.hasOwnProperty("beneficiaries");

  const quote = isLink
    ? {
        quote: {
          ...data?.quote,
          ...data?.quote_detail,
          beneficiaries: [...data?.beneficiaries],
        },
      }
    : data;

  const value = {
    data: quote,
    isLink,
  };

  return (
    <QuoteContext.Provider value={value}>{children}</QuoteContext.Provider>
  );
}

export function useQuote() {
  const context = useContext(QuoteContext);
  if (!context) {
    throw new Error("useData debe ser utilizado dentro de un DataProvider");
  }
  return context;
}
