import React, { createContext, useContext, useState } from "react";

const ActionButtonContext = createContext();
export const useActionButton = () => {
  return useContext(ActionButtonContext);
};
export const ActionButtonProvider = ({ children }) => {
  const [actionButtonLabel, setActionButtonLabel] = useState(null);
  const [actionButtonEnabled, setActionButtonEnabled] = useState(true);
  const [actionButtonAvailable, setActionButtonAvailable] = useState(true);

  const contextValue = {
    actionButtonEnabled,
    setActionButtonEnabled,
    actionButtonLabel,
    setActionButtonLabel,
    actionButtonAvailable,
    setActionButtonAvailable,
  };

  return (
    <ActionButtonContext.Provider value={contextValue}>
      {children}
    </ActionButtonContext.Provider>
  );
};
