import React from "react";
import { useLocation } from "../context/locationContext";
import Checkout from "../view/Checkout";
import Congrats from "../view/Congrats";
import A365Logo from "../assets/illustrations/svg/a365logo";
import { useQuote } from "../context/quoteContext";
import AgeException from "../view/AgeException";

const Route = ({ setPrismaActivation }) => {
  const { isCongrats, isAgeException } = useLocation();
  const { isLink } = useQuote();

  const renderCheckout = typeof isLink !== "undefined" ? <Checkout setPrismaActivation={setPrismaActivation} /> : null;

  return (
    <>
      <div className="flex items-center w-full bg-[#0371e8] py-3 px-6">
        <A365Logo width="45px" />
      </div>
      {!isCongrats && !isAgeException ? renderCheckout : (isCongrats ? <Congrats /> : <AgeException />)}
    </>
  );
};

export default Route;
