import React, { useCallback, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import BackButton from "../../components/BackButton";
import PersonalData from "../../steps/PersonalData";
import BillingData from "../../steps/BillingData";
import PaymentMethods from "../../steps/PaymentMethods";
import Payment from "../../steps/Payment";
import { Alert, Button, FormControlLabel, Link } from "@mui/material";
import PurchaseDetail from "../../components/PurchaseDetail";
import StepMonitor from "../../components/StepMonitor";
import { useQuote } from "../../context/quoteContext";
import { mapPurchaseDetailData } from "../../helpers/dataMapper";
import StepContent from "../../components/StepContent";
import { useFormData } from "../../context/formContext";
import { useLanguage } from "../../context/languageContext";
import { usePaymentMethod } from "../../context/paymentMethodContext";
import { useLocation } from "../../context/locationContext";
import { useCountries } from "../../context/countriesContext";
import { useActionButton } from "../../context/actionButtonContext";
import { useSteps } from "../../context/stepsContext";
import CustomerDetail from "../../components/CustomerDetail";
import Typography from "@mui/material/Typography";
import Chubb from "../../components/Chubb";
import "./styles.css";
import AgeException from "../AgeException";

const Checkout = ({ setPrismaActivation }) => {

  const { language } = useLanguage();
  const { geolocation, geoUrl } = useLocation();
  const { t } = useTranslation();
  const { getFormData, setFormData } = useFormData();
  const { activeStep, setActiveStep } = useSteps();
  const { data, isLink } = useQuote();

  const {
    quote: { input_total: total_pax, beneficiaries = [], currency_symbol } = {},
    upgrades = {},
  } = data || {};

  const scrollNode = useRef(null);
  const scrollTopNode = useRef(null);
  const { countries } = useCountries();
  const { actionButtonEnabled, setActionButtonEnabled, actionButtonAvailable } = useActionButton();
  const { paymentMethod, setPaymentMethodLink } = usePaymentMethod();

  const [isCheckedTyC, setIsCheckedTyC] = useState(false);
  const [passengerForm, setPassengerForm] = useState(null);
  const [isValidPassengerForm, setIsValidPassengerForm] = useState(false);
  const [customerForm, setCustomerForm] = useState(null);
  const [isValidCustomerForm, setIsValidCustomerForm] = useState(false);
  const [paymentForm, setPaymentForm] = useState(null);
  const [isValidPaymentForm, setIsValidPaymentForm] = useState(false);
  const [purchaseDetail, setPurchaseDetail] = useState(null);
  const backToProducts = `/${geoUrl?.toLowerCase()}/products`;
  const [depositDiscount, setDepositDiscount] = useState(null);

  const [pricingStatus, setPricingStatus] = useState({ error: false, msg: null });
  const [exception, setException] = useState({ status: null, age_alert: null, continue: null, visibility: null });

  const idsContinueButton = [
    "continuarCheckoutDatosPersonales",
    "continuarCheckoutAdicionales",
    "continuarCheckoutMediosPago",
    "continuarCheckoutPago"
  ]

  const [IdContinueButton, setIdContinueButton] = useState(idsContinueButton[0]);

  useEffect(() => {
    setActionButtonEnabled(!pricingStatus.error);
  }, [pricingStatus]);

  useEffect(() => {

    try {

      const node = scrollTopNode.current;
      const targetPosition = node.offsetTop;

      window.scrollTo({
        top: targetPosition,
        behavior: "instant"
      });


      if (activeStep === 1) {
        setPrismaActivation(true);
      }

      if (activeStep <= 3) {
        setIdContinueButton(idsContinueButton[activeStep]);
      }
    } catch (error) {
      console.error(error);
    }
  }, [activeStep]);

  const steps = !isLink
    ? [
      t("index.personalDataStepTitle"),
      t("index.billingDataStepTitle"),
      t("index.paymentMethodsStepTitle"),
      t("index.paymentStepTitle"),
    ]
    : [
      t("index.personalDataStepTitle"),
      t("index.paymentMethodsStepTitle"),
      t("index.paymentStepTitle"),
    ];

  const isTyCAvailable =
    activeStep === 0 || (activeStep === 1 && language === "pt-BR");

  const buttonLabel =
    paymentMethod === "deposit" ||
      paymentMethod === "Pix" ||
      paymentMethod === "mp"
      ? t("index.finish")
      : t("index.pay");

  const stepperContinueButton =
    activeStep !== steps.length - 1 ? t("index.continue") : buttonLabel;

  const handleScroll = () => {
    const node = scrollNode.current;
    const offset = 20;
    const targetPosition = node.offsetTop - offset;

    window.scrollTo({
      top: targetPosition,
      behavior: "smooth",
    });
  };

  const onSubmitPassengerData = (data) => {

    // WIP . data.discode (undefined)
    // console.log('data ------');
    // console.log(data);
    
    // FIX FIX 

    if (data.hasOwnProperty('discode') || data.hasOwnProperty('discode_dual')) {
      if (data.hasOwnProperty('discode')) {
        delete data.discode;
      }
      if (data.hasOwnProperty('discode_dual')) {
        delete data.discode_dual;
      }
    }
    setFormData((prev) => ({ ...prev, ...data }));
  };

  const onSubmitCustomerData = (data) => {

    // WIP FIX: data is not updated with the new values

    const updatedData = { ...data, ...getFormData() };

    Object.keys(data).forEach(key => {
      if (key.includes('upgrade')) {
        updatedData[key] = data[key];
      }
    });

    setFormData((prev) => ({ ...prev, ...updatedData }));
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) =>
      Math.min(prevActiveStep + 1, steps.length - 1)
    );
  };

  const handleBack = () => {
    if (activeStep - 1 < 0) {
      window.location.href = backToProducts;
    } else {
      setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
    }
  };

  const continueWorkflow = useCallback(() => {


    if (!isLink) {

      if (activeStep === 0 && passengerForm?.current) {
        passengerForm.current.requestSubmit();
        if (exception.status === true) {
          setException({ ...exception, visibility: true });
        } else {
          isValidPassengerForm && handleNext();
        }
      }

      if (activeStep === 1 && customerForm?.current) {
        customerForm.current.requestSubmit();
        isValidCustomerForm && handleNext();
      }

      if (activeStep === 2) {
        handleNext();
      }

      if (activeStep === 3) {
        if (paymentForm?.current) {
          paymentForm.current.requestSubmit();
        }
      }
    } else {
      if (activeStep === 0) {
        handleNext();
      }

      if (activeStep === 1) {
        handleNext();
      }
      if (activeStep === 2) {
        if (paymentForm?.current) {
          paymentForm.current.requestSubmit();
        }
      }
    }
  }, [
    passengerForm,
    isValidPassengerForm,
    activeStep,
    customerForm,
    isValidCustomerForm,
    paymentMethod,
    paymentForm,
    isValidPaymentForm,
    isLink,
    exception
  ]);

  const renderAlertMsg = useCallback(() => {
    return language === "pt-BR" ? (
      <div className="mb-6 sm:grid grid-cols-[50%]">
        <Alert
          severity="warning"
          variant="filled"
          sx={{
            borderRadius: "10px",
            backgroundColor: "#FFF7B0",
            borderColor: "#EDC95E",
            color: "#453608",
            fontWeight: "normal",
            border: "solid 1px #EDC95E",
            boxShadow: "none",
          }}
        >
          {t("index.attentionMessage")}{" "}
          <Link
            href="checkout/src/view"
            fontWeight={"bold"}
            fontStyle={{ color: "#453608" }}
            className="text-sm ml-4 underline"
          >
            {t("index.here")}
          </Link>
        </Alert>
      </div>
    ) : null;
  }, [language]);


  useEffect(() => {
    if (!isTyCAvailable) {
      setIsCheckedTyC(true);
    }
  }, [isTyCAvailable]);

  useEffect(() => {
    if (data) {

      // console.log(data);
      const detail = mapPurchaseDetailData(data.quote, isLink, language);
      setPurchaseDetail(detail);
    }
  }, [data, language, isLink, depositDiscount]);

  useEffect(() => {
    if (isLink) {
      const method = data?.quote?.payment_processor;
      setPaymentMethodLink(method);
    }
  }, [isLink, data]);

  const continuePurchase = (mode) => {

    const continueAction = mode === "continue" ? true : false;
    setException({ ...exception, status: false, continue: continueAction, visibility: false });

    if (mode === "continue") {
      isValidPassengerForm && handleNext();
    }

  }

  return (
    <>
      {exception.status && exception.visibility ? (<AgeException exception={exception} continuePurchase={continuePurchase} />) :
        (
          <main className={`md:max-w-[1100px] mx-auto bg-[#EFF1F4] px-5 py-8 relative`} ref={scrollTopNode}>
            <div className="mb-5 sm:flex w-full sm:flex-col lg:flex-row">
              {!isLink && (
                <div className="mb-4 sm:mb-0">
                  <BackButton label={t("index.back")} event={handleBack} />
                </div>
              )}
              <StepMonitor activeStep={activeStep} steps={steps} isLink={isLink} />
            </div>
            <div className="mt-1 flex justify-end">
              <Link
                onClick={handleScroll}
                className="text-xs text-[#675CE5] underline sm:hidden"
              >
                {t("index.viewPurchaseDetail")}
              </Link>
            </div>
            <div className={`max-w-[1130px] ml-auto ${isLink && "max-w-md mx-auto"}`}>

              {!isLink && pricingStatus.error && (<div className="flex flex-col md:flex-row justify-between items-center notification-error">{pricingStatus.msg}

                <BackButton label={t("index.back")} event={handleBack} />

              </div>)}

              <div
                className={`mb-12 mt-6 ${!isLink && "sm:grid grid-cols-[45%_52%] md:grid-cols-[50%_47%] lg:grid-cols-[50%_40%]"
                  } w-full justify-between sm:mb-6`}
              >
                {!isLink ? (
                  <StepContent activeStep={activeStep} steps={steps}>
                    <PersonalData
                      setException={setException}
                      setActionButtonEnabled={setActionButtonEnabled}
                      exception={exception}
                      setPricingStatus={setPricingStatus}
                      stepName={t("index.personalData")}
                      total_pax={total_pax}
                      onSubmit={onSubmitPassengerData}
                      isValidForm={setIsValidPassengerForm}
                      passengerFormRef={setPassengerForm}
                      defaultValues={getFormData()}
                      setPurchaseDetail={setPurchaseDetail}
                      countries={countries}
                    />
                    <BillingData
                      stepName={t("index.billingData")}
                      onSubmit={onSubmitCustomerData}
                      totalPax={total_pax}
                      currency_symbol={currency_symbol}
                      isValidForm={setIsValidCustomerForm}
                      customerFormRef={setCustomerForm}
                      defaultValues={getFormData()}
                      upgrades={upgrades}
                      setPurchaseDetail={setPurchaseDetail}
                    />
                    <PaymentMethods
                      stepName={t("index.paymentMethods")}
                      purchaseDetail={purchaseDetail}
                      isLink={isLink}
                      setPurchaseDetail={setPurchaseDetail}
                      setDepositDiscount={setDepositDiscount}
                    />
                    <Payment
                      stepName={t("index.payment")}
                      isValidForm={setIsValidPaymentForm}
                      paymentFormRef={setPaymentForm}
                      totalPax={total_pax}
                      defaultValues={getFormData()}
                      purchaseDetail={purchaseDetail}
                    />
                  </StepContent>
                ) : (
                  <StepContent activeStep={activeStep} steps={steps}>
                    <div className="mb-8 w-full">
                      <Chubb
                        summary={
                          <Typography fontSize={20} fontWeight={600} color="#1B2225">
                            {`${t("customerDetail.title")}`}
                          </Typography>
                        }
                      >
                        {beneficiaries.map((row, index) => (
                          <CustomerDetail key={index} detail={row} />
                        ))}
                      </Chubb>
                    </div>
                    <PaymentMethods
                      stepName={t("index.paymentMethods")}
                      purchaseDetail={purchaseDetail}
                    />
                    <Payment
                      stepName={t("index.payment")}
                      isValidForm={setIsValidPaymentForm}
                      paymentFormRef={setPaymentForm}
                      totalPax={total_pax}
                      defaultValues={getFormData()}
                      purchaseDetail={purchaseDetail}
                    />
                  </StepContent>
                )}

                <div className={` flex-col-reverse sm:flex align-top justify-end`}>
                  <div
                    className={`${!isTyCAvailable
                      ? "mb-16 sm:flex justify-center sm:mb-0 sm:mt-7 hidden"
                      : "mb-16 sm:flex justify-center sm:mb-0 sm:mt-7"
                      }  `}
                  >

                    <div className="checkbox-container flex">
                      <label className="checkbox mr-4 block">
                        <input type="checkbox" className="checkbox__input" checked={isCheckedTyC} onChange={() => setIsCheckedTyC((prev) => !prev)} />
                        <span className="checkbox__inner"></span>
                      </label>
                      <div>
                        <Trans
                          i18nKey="index.termsAndConditions"
                          components={[
                            <Link
                              href={data?.quote.hasOwnProperty('ccgg') ? data?.quote.ccgg : '#'}
                              target="_blank"
                              style={{
                                color: "#675CE5",
                                fontWeight: "bold",
                                textDecorationColor: "#675CE5",
                                marginLeft: "4px",
                              }}
                            />,
                          ]}
                        >
                          {"I accept the <0>terms and conditions</0>."}
                        </Trans>
                      </div>
                    </div>
                  </div>
                  {actionButtonAvailable && (

                    <div className="fixed z-10 p-5 bottom-0 w-full left-0 bg-[#ffffff] rounded-t-[10px] sm:relative shadow-none sm:bg-transparent sm:p-0 mt-7 ">
                      <button
                        className="btn-continue-workflow"
                        id={IdContinueButton}
                        type="submit"
                        disabled={!isCheckedTyC || !actionButtonEnabled}
                        onClick={continueWorkflow}
                      >
                        {stepperContinueButton}
                      </button>
                      {/* <Button
                        id={IdContinueButton}
                        type="submit"
                        variant="contained"
                        fullWidth
                        disabled={!isCheckedTyC || !actionButtonEnabled}
                        onClick={continueWorkflow}
                        style={{
                          padding: "12px 0",
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        {stepperContinueButton}
                      </Button> */}
                    </div>
                  )}
                  <div ref={scrollNode}>
                    <PurchaseDetail
                      purchaseDetail={purchaseDetail}
                      currency={geolocation?.currency_symbol}
                    />
                  </div>
                </div>
              </div>
              {/* <>{renderAlertMsg()}</> */}
            </div>
          </main>
        )}
    </>
  );
};

export default Checkout;
