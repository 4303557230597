import {
  emitPOSTController,
  userFollowEndPOSTController,
  billingPOSTController,
  payPOSTController,
  sendEmailNotificationPOSTController,
} from "../dataController/dataController";

export const emit = async (parsedData) => {
  try {
    const result = await emitPOSTController.fetchData({
      body: parsedData,
    });

    const reserve = result?.data?.reserve;

    if (result?.data?.status) {
      return {
        reserve_id: reserve?.new_id,
        reserve_status: result?.data?.status,
      };
    }
    return null;
  } catch (error) {
    console.error("Error during submission:", error);
    throw error;
  }
};

export const endFollowProcess = async (parsedData, reserveData, method) => {
  try {
    return await userFollowEndPOSTController.fetchData({
      body: {
        follow_id: parsedData?.user_follow_id,
        reserve_id: reserveData.reserve_id,
        payment_method: method,
      },
    });
  } catch (error) {
    console.error("Error during submission:", error);
    throw error;
  }
};

export const emitVoucher = async (parsedData, reserveData) => {
  try {

    const body = {
      ...parsedData,
      affiliate_code: parsedData?.discode || "",
    };

    if (parsedData?.discode_dual) {
      body["affiliate_code_dual"] = parsedData?.discode_dual;
    }

    return await emitPOSTController.fetchData(
      {
        body: body,
      },
      reserveData?.reserve_id
    );

    // ORIGINAL CODE
    // return await emitPOSTController.fetchData(
    //   {
    //     body: {
    //       ...parsedData,
    //       affiliate_code: parsedData?.discode || "",
    //     },
    //   },
    //   reserveData?.reserve_id
    // );
  } catch (error) {
    console.error("Error during submission:", error);
    throw error;
  }
};

export const fetchBilling = async (billingData) => {
  try {
    return await billingPOSTController.fetchData({
      body: billingData,
    });
  } catch (error) {
    console.error("Error during submission:", error);
    throw error;
  }
};

export const fetchPay = async (body) => {
  try {
    return await payPOSTController.fetchData({
      body,
    });
  } catch (error) {
    console.error("Error during submission:", error);
    throw error;
  }
};

export const sendDepositNotification = async (depositData) => {
  try {
    return await sendEmailNotificationPOSTController.fetchData({
      body: depositData,
    });
  } catch (error) {
    console.error("Error during submission:", error);
    throw error;
  }
};
