import React from "react";
import { initMercadoPago, Wallet } from "@mercadopago/sdk-react";

const MercadoPago = ({purchaseDetail}) => {
  //TODO tomarlo desde el endpoint de getMethods
  initMercadoPago("APP_USR-efbcf7cc-cf1b-43af-bcdf-bf43746cb1bc");

  const onSubmit = async (formData) => {
    const yourRequestBodyHere = {
      items: [
        {
          id: "202809963",
          title: "Dummy title",
          description: "Dummy description",
          quantity: 1,
          unit_price: 10,
        },
      ],
      purpose: "wallet_purchase",
    };
    return new Promise((resolve, reject) => {
      fetch("/create_preference", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(yourRequestBodyHere),
      })
        .then((response) => response.json())
        .then((response) => {
          // resolver la promesa con el ID de la preferencia
          resolve(response.preference_id);
        })
        .catch((error) => {
          // manejar la respuesta de error al intentar crear preferencia
          reject();
        });
    });
  };

  const onError = async (error) => {
    // callback llamado para todos los casos de error de Brick
    console.log(error);
  };

  const onReady = async () => {
    /*
      Callback llamado cuando Brick está listo.
      Aquí puedes ocultar loadings de su sitio, por ejemplo.
    */
  };

  return (
    <Wallet
      onSubmit={onSubmit}
      onReady={onReady}
      onError={onError}
      locale="es-AR"
    />
  );
};

export default MercadoPago;
