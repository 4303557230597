import React, { createContext, useContext, useEffect, useMemo } from "react";
import i18n from "i18next";

const LanguageContext = createContext();
export const useLanguage = () => {
  return useContext(LanguageContext);
};
export const LanguageProvider = ({ children }) => {
  let languageCode;
  if (window.localStorage.getItem("lang")) {
    languageCode = window.localStorage.getItem("lang") === "pt" ? "br" : "ar";
  } else {
    const url = window.location.href;
    languageCode = url.split("/")[3];
  }

  const getLanguageFromGeo = useMemo(() => {
    switch (languageCode) {
      case "br":
        return "pt-BR";
      case "ar":
        return "es-AR";
      default:
        return "es";
    }
  }, [languageCode]);

  const changeLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
  };

  useEffect(() => {
    i18n.changeLanguage(getLanguageFromGeo);
  }, [getLanguageFromGeo]);

  const contextValue = {
    language: getLanguageFromGeo,
    setLanguage: changeLanguage,
  };

  return (
    <LanguageContext.Provider value={contextValue}>
      {children}
    </LanguageContext.Provider>
  );
};
